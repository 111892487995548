const SvgComponent = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		style={{
			enableBackground: 'new 0 0 841.9 595.3',
		}}
		viewBox='0 0 841.9 595.3'
		{...props}>
		<style>{'.st0{fill:#9e9e9e}.st1{fill:#262533}'}</style>
		<g id='Layer_4'></g>
		<g id='Layer_6'></g>
		<g id='Layer_5'></g>
		<g id='Layer_2'>
			<g className='notice'>
				<path
					className='st0'
					d='M259.8,397.9v-10h1.4c1.5,2.4,4.9,7.7,5.5,8.8h0c-0.1-1.4-0.1-2.9-0.1-4.5v-4.2h1v10h-1.3
			c-1.4-2.2-4.9-7.9-5.6-9h0c0.1,1.2,0.1,2.8,0.1,4.6v4.4H259.8z'
				/>
				<path
					className='st0'
					d='M271.8,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1
			c-2.2,0-3.1-1.9-3.1-3.7c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H271.8z M276.1,393.7c0-1.3-0.7-2.4-2-2.4
			c-1.4,0-2,1-2.2,2.4H276.1z'
				/>
				<path
					className='st0'
					d='M280.4,390.7c1,3.6,1.6,5.6,1.7,6.3h0c0.1-0.6,0.7-2.7,1.8-6.3h1c1.2,4.3,1.6,5.5,1.7,6.1h0
			c0.2-0.8,0.5-1.9,1.7-6.1h1l-2.3,7.2h-1c-0.7-2.6-1.6-5.4-1.7-6.1h0c-0.1,0.7-0.9,3.1-1.8,6.1h-1.1l-2.1-7.2H280.4z'
				/>
				<path
					className='st0'
					d='M306,393.4c0-1.9,0-3.8,0.1-4.8h0c-0.4,1.5-2.3,6.1-3.5,9.3h-1c-0.9-2.5-2.8-7.7-3.3-9.3h0
			c0.1,1.1,0.1,3.4,0.1,5.1v4.1h-1v-10h1.5c1.3,3.3,2.8,7.6,3.2,8.7h0c0.2-0.8,2.1-5.6,3.4-8.7h1.5v10h-1V393.4z'
				/>
				<path
					className='st0'
					d='M311.1,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1
			c-2.2,0-3.1-1.9-3.1-3.7c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H311.1z M315.4,393.7c0-1.3-0.7-2.4-2-2.4
			c-1.4,0-2,1-2.2,2.4H315.4z'
				/>
				<path
					className='st0'
					d='M319.5,390.7c1.1,1.7,1.6,2.4,1.9,2.9h0c0.3-0.5,1-1.5,1.9-2.9h1.1l-2.5,3.5l2.5,3.7h-1.1c-1-1.5-1.7-2.5-2-3
			h0c-0.3,0.4-0.9,1.4-1.9,3h-1.1l2.5-3.6l-2.4-3.6H319.5z'
				/>
				<path
					className='st0'
					d='M327.1,387.3h1v1.5h-1V387.3z M327.1,390.7h1v7.2h-1V390.7z'
				/>
				<path
					className='st0'
					d='M337.1,395.7c-0.3,1.3-1.2,2.3-2.9,2.3c-1.9,0-3.1-1.4-3.1-3.7c0-2,1.1-3.8,3.2-3.8c1.9,0,2.7,1.4,2.8,2.4h-1
			c-0.2-0.8-0.7-1.5-1.8-1.5c-1.4,0-2.2,1.2-2.2,2.9c0,1.7,0.8,2.9,2.2,2.9c1,0,1.6-0.5,1.9-1.5H337.1z'
				/>
				<path
					className='st0'
					d='M346,394.2c0,2-1.1,3.8-3.3,3.8c-2,0-3.2-1.6-3.2-3.8c0-2.1,1.2-3.8,3.3-3.8C344.7,390.5,346,392,346,394.2z
			 M340.5,394.3c0,1.7,0.9,2.9,2.3,2.9c1.4,0,2.3-1.2,2.3-2.9c0-1.7-0.8-2.9-2.3-2.9C341.2,391.3,340.5,392.6,340.5,394.3z'
				/>
				<path
					className='st0'
					d='M361.9,395.1c-0.5,1.6-1.7,2.9-3.9,2.9c-2.9,0-4.4-2.3-4.4-5.1c0-2.7,1.5-5.2,4.5-5.2c2.2,0,3.5,1.3,3.8,2.9
			h-1c-0.4-1.2-1.2-2-2.9-2c-2.4,0-3.3,2.2-3.3,4.2c0,2.1,1,4.2,3.4,4.2c1.6,0,2.3-0.9,2.8-2H361.9z'
				/>
				<path
					className='st0'
					d='M370.1,396.4c0,0.6,0.1,1.2,0.1,1.5h-0.9c-0.1-0.2-0.1-0.5-0.1-1c-0.2,0.5-0.9,1.2-2.3,1.2
			c-1.7,0-2.4-1.1-2.4-2.2c0-1.6,1.2-2.3,3.4-2.3c0.6,0,1,0,1.3,0v-0.7c0-0.7-0.2-1.6-1.7-1.6c-1.3,0-1.6,0.7-1.7,1.3h-0.9
			c0.1-0.9,0.6-2.1,2.7-2.1c1.7,0,2.6,0.7,2.6,2.3V396.4z M369.1,394.3c-0.3,0-0.9,0-1.3,0c-1.5,0-2.4,0.4-2.4,1.5
			c0,0.8,0.6,1.4,1.5,1.4c1.9,0,2.2-1.3,2.2-2.7V394.3z'
				/>
				<path
					className='st0'
					d='M373.6,392.5c0-0.6,0-1.2,0-1.8h0.9c0,0.2,0,1.1,0,1.3c0.3-0.7,0.9-1.5,2.3-1.5c1.3,0,2.4,0.8,2.4,2.7v4.7h-1
			v-4.5c0-1.2-0.5-2-1.7-2c-1.6,0-2.1,1.2-2.1,2.8v3.7h-1V392.5z'
				/>
				<path
					className='st0'
					d='M382.9,392.5c0-0.6,0-1.2,0-1.8h0.9c0,0.2,0,1.1,0,1.3c0.3-0.7,0.9-1.5,2.3-1.5c1.3,0,2.4,0.8,2.4,2.7v4.7h-1
			v-4.5c0-1.2-0.5-2-1.7-2c-1.6,0-2.1,1.2-2.1,2.8v3.7h-1V392.5z'
				/>
				<path
					className='st0'
					d='M397.2,396.4c0,0.6,0.1,1.2,0.1,1.5h-0.9c-0.1-0.2-0.1-0.5-0.1-1c-0.2,0.5-0.9,1.2-2.3,1.2
			c-1.7,0-2.4-1.1-2.4-2.2c0-1.6,1.2-2.3,3.4-2.3c0.6,0,1,0,1.3,0v-0.7c0-0.7-0.2-1.6-1.7-1.6c-1.3,0-1.6,0.7-1.7,1.3h-0.9
			c0.1-0.9,0.6-2.1,2.7-2.1c1.7,0,2.6,0.7,2.6,2.3V396.4z M396.2,394.3c-0.3,0-0.9,0-1.3,0c-1.5,0-2.4,0.4-2.4,1.5
			c0,0.8,0.6,1.4,1.5,1.4c1.9,0,2.2-1.3,2.2-2.7V394.3z'
				/>
				<path
					className='st0'
					d='M400.7,387.3h1v4.5c0.3-0.7,1-1.3,2.4-1.3c1.7,0,3,1.4,3,3.6c0,2.2-1.1,3.9-3.1,3.9c-1.2,0-1.8-0.5-2.2-1.2
			c0,0.4,0,0.9,0,1.1h-0.9c0-0.8,0-1.6,0-2.4V387.3z M406,394.1c0-1.7-0.8-2.8-2.1-2.8c-1.7,0-2.3,1.1-2.3,2.9
			c0,1.6,0.5,2.9,2.2,2.9C405.2,397.2,406,396,406,394.1z'
				/>
				<path
					className='st0'
					d='M410.1,387.3h1v1.5h-1V387.3z M410.1,390.7h1v7.2h-1V390.7z'
				/>
				<path
					className='st0'
					d='M414.8,395.8c0.2,0.9,0.9,1.4,1.9,1.4c1.2,0,1.7-0.5,1.7-1.3c0-0.8-0.3-1.1-1.8-1.5c-2-0.5-2.4-1.1-2.4-2
			c0-1,0.7-2,2.5-2c1.7,0,2.5,1,2.6,2h-0.9c-0.1-0.5-0.5-1.2-1.7-1.2c-1.1,0-1.5,0.6-1.5,1.1c0,0.6,0.3,0.9,1.7,1.2
			c2.1,0.5,2.6,1.2,2.6,2.3c0,1.3-1,2.1-2.7,2.1c-1.7,0-2.7-0.9-2.9-2.2H414.8z'
				/>
				<path
					className='st0'
					d='M427.5,387.9h3.4c2.8,0,4.7,1.9,4.7,4.9c0,3-1.9,5.1-4.7,5.1h-3.4V387.9z M428.6,397h2.3
			c2.5,0,3.7-1.8,3.7-4.2c0-2.1-1.1-4-3.7-4h-2.3V397z'
				/>
				<path
					className='st0'
					d='M439.2,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1
			c-2.2,0-3.1-1.9-3.1-3.7c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H439.2z M443.5,393.7c0-1.3-0.7-2.4-2-2.4
			c-1.4,0-2,1-2.2,2.4H443.5z'
				/>
				<path className='st0' d='M447.5,397.9v-10.5h1v10.5H447.5z' />
				<path
					className='st0'
					d='M452,387.3h1v1.5h-1V387.3z M452,390.7h1v7.2h-1V390.7z'
				/>
				<path
					className='st0'
					d='M456.7,390.7c1.1,3.1,1.8,5.4,2.1,6.3h0c0.2-0.7,1-2.8,2.2-6.3h1l-2.7,7.2h-1.1l-2.5-7.2H456.7z'
				/>
				<path
					className='st0'
					d='M465,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1c-2.2,0-3.1-1.9-3.1-3.7
			c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H465z M469.2,393.7c0-1.3-0.7-2.4-2-2.4c-1.4,0-2,1-2.2,2.4H469.2z'
				/>
				<path
					className='st0'
					d='M473.2,392.6c0-1.1,0-1.7,0-2h0.9c0,0.2,0,0.7,0,1.5c0.4-1,1.3-1.7,2.5-1.7v1c-1.5,0.1-2.5,1-2.5,2.8v3.6h-1
			V392.6z'
				/>
				<path
					className='st0'
					d='M479.8,390.7c1.3,3.8,2,5.6,2.2,6.4h0c0.3-0.9,0.8-2.5,2.1-6.4h1l-2.7,7.6c-0.7,2.1-1.3,2.6-2.7,2.6
			c-0.2,0-0.5,0-0.7,0v-0.9c0.2,0,0.4,0,0.6,0c1,0,1.3-0.5,1.8-1.8l-2.7-7.5H479.8z'
				/>
				<path className='st0' d='M486.5,397.9v-2h1v2H486.5z' />
				<path
					className='st0'
					d='M504.6,393.4c0-1.9,0-3.8,0.1-4.8h0c-0.4,1.5-2.3,6.1-3.5,9.3h-1c-0.9-2.5-2.8-7.7-3.3-9.3h0
			c0.1,1.1,0.1,3.4,0.1,5.1v4.1h-1v-10h1.5c1.3,3.3,2.8,7.6,3.2,8.7h0c0.2-0.8,2.1-5.6,3.4-8.7h1.5v10h-1V393.4z'
				/>
				<path
					className='st0'
					d='M514.8,395.8c0,0.7,0,1.8,0,2.1h-0.9c0-0.2,0-0.6,0-1.2c-0.3,0.8-1,1.3-2.3,1.3c-1.1,0-2.4-0.5-2.4-2.6v-4.8
			h0.9v4.5c0,1,0.3,2,1.6,2c1.5,0,2-0.8,2-2.8v-3.7h1V395.8z'
				/>
				<path
					className='st0'
					d='M518.5,395.8c0.2,0.9,0.9,1.4,1.9,1.4c1.2,0,1.7-0.5,1.7-1.3c0-0.8-0.3-1.1-1.8-1.5c-2-0.5-2.4-1.1-2.4-2
			c0-1,0.7-2,2.5-2c1.7,0,2.5,1,2.6,2H522c-0.1-0.5-0.5-1.2-1.7-1.2c-1.1,0-1.5,0.6-1.5,1.1c0,0.6,0.3,0.9,1.7,1.2
			c2.1,0.5,2.6,1.2,2.6,2.3c0,1.3-1,2.1-2.7,2.1c-1.7,0-2.7-0.9-2.9-2.2H518.5z'
				/>
				<path
					className='st0'
					d='M525,390.7h1.3v-2.2h1v2.2h1.7v0.8h-1.7v4.5c0,0.8,0.2,1.2,0.8,1.2c0.2,0,0.5,0,0.7-0.1v0.8
			c-0.3,0.1-0.7,0.1-1,0.1c-1,0-1.5-0.5-1.5-1.7v-4.8H525V390.7z'
				/>
				<path
					className='st0'
					d='M536.6,387.3h1v4.5c0.3-0.7,1-1.3,2.4-1.3c1.7,0,3,1.4,3,3.6c0,2.2-1.1,3.9-3.1,3.9c-1.2,0-1.8-0.5-2.2-1.2
			c0,0.4,0,0.9,0,1.1h-0.9c0-0.8,0-1.6,0-2.4V387.3z M541.9,394.1c0-1.7-0.8-2.8-2.1-2.8c-1.7,0-2.3,1.1-2.3,2.9
			c0,1.6,0.5,2.9,2.2,2.9C541.1,397.2,541.9,396,541.9,394.1z'
				/>
				<path
					className='st0'
					d='M546.4,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1
			c-2.2,0-3.1-1.9-3.1-3.7c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H546.4z M550.6,393.7c0-1.3-0.7-2.4-2-2.4
			c-1.4,0-2,1-2.2,2.4H550.6z'
				/>
				<path
					className='st0'
					d='M559,397.9L559,397.9c0-1.3,1-2.8,2.9-4.2c1.6-1.2,2-1.6,2-2.7c0-1-0.5-1.8-1.8-1.8c-1.2,0-1.8,0.6-2.1,1.9
			h-1c0.2-1.6,1.2-2.7,3-2.7c2,0,2.8,1.3,2.8,2.6c0,1.4-0.6,2-2.4,3.4c-1.3,1.1-2.1,1.9-2.4,2.7h5.1l-0.2,0.9H559z'
				/>
				<path
					className='st0'
					d='M569.8,397.9v-8.3c-0.5,0.4-1.5,0.9-2,1v-0.8c0.7-0.2,1.6-0.8,2.3-1.4h0.8v9.5H569.8z'
				/>
				<path
					className='st0'
					d='M578.9,389.9v3.5h3.5v0.9h-3.5v3.6H578v-3.6h-3.5v-0.9h3.5v-3.5H578.9z'
				/>
				<path
					className='st0'
					d='M584.7,399.5c0.7-1.5,1-2.7,1-3.6h1c-0.1,1.2-0.6,2.7-1.2,3.6H584.7z'
				/>
				<path
					className='st0'
					d='M601,393.1h-5v3.9h5.5l-0.1,0.9H595v-10h6.3v0.9H596v3.4h5V393.1z'
				/>
				<path
					className='st0'
					d='M604.4,392.5c0-0.6,0-1.2,0-1.8h0.9c0,0.2,0,1.1,0,1.3c0.3-0.7,0.9-1.5,2.3-1.5c1.3,0,2.4,0.8,2.4,2.7v4.7h-1
			v-4.5c0-1.2-0.5-2-1.7-2c-1.6,0-2.1,1.2-2.1,2.8v3.7h-1V392.5z'
				/>
				<path
					className='st0'
					d='M614.6,390.7v7.6c0,2-0.5,2.6-1.9,2.6c-0.1,0-0.3,0-0.4,0V400c0.1,0,0.2,0,0.3,0c1,0,1.1-0.5,1.1-1.6v-7.7
			H614.6z M613.6,388.8v-1.5h1v1.5H613.6z'
				/>
				<path
					className='st0'
					d='M624.1,394.2c0,2-1.1,3.8-3.3,3.8c-2,0-3.2-1.6-3.2-3.8c0-2.1,1.2-3.8,3.3-3.8
			C622.8,390.5,624.1,392,624.1,394.2z M618.6,394.3c0,1.7,0.9,2.9,2.3,2.9c1.4,0,2.3-1.2,2.3-2.9c0-1.7-0.8-2.9-2.3-2.9
			C619.3,391.3,618.6,392.6,618.6,394.3z'
				/>
				<path
					className='st0'
					d='M627.3,390.7c1.3,3.8,2,5.6,2.2,6.4h0c0.3-0.9,0.8-2.5,2.1-6.4h1l-2.7,7.6c-0.7,2.1-1.3,2.6-2.7,2.6
			c-0.2,0-0.5,0-0.7,0v-0.9c0.2,0,0.4,0,0.6,0c1,0,1.3-0.5,1.8-1.8l-2.7-7.5H627.3z'
				/>
				<path
					className='st0'
					d='M640.2,392.6c0-1.1,0-1.7,0-2h0.9c0,0.2,0,0.7,0,1.5c0.4-1,1.3-1.7,2.5-1.7v1c-1.5,0.1-2.5,1-2.5,2.8v3.6h-1
			V392.6z'
				/>
				<path
					className='st0'
					d='M646.6,394.4c0,1.7,0.9,2.8,2.2,2.8c1.3,0,1.7-0.7,1.9-1.2h1c-0.3,0.9-1.1,2.1-2.9,2.1
			c-2.2,0-3.1-1.9-3.1-3.7c0-2.1,1-3.8,3.2-3.8c2.3,0,3,1.9,3,3.3c0,0.2,0,0.4,0,0.6H646.6z M650.8,393.7c0-1.3-0.7-2.4-2-2.4
			c-1.4,0-2,1-2.2,2.4H650.8z'
				/>
				<path
					className='st0'
					d='M655.2,395.8c0.2,0.9,0.9,1.4,1.9,1.4c1.2,0,1.7-0.5,1.7-1.3c0-0.8-0.3-1.1-1.8-1.5c-2-0.5-2.4-1.1-2.4-2
			c0-1,0.7-2,2.5-2c1.7,0,2.5,1,2.6,2h-0.9c-0.1-0.5-0.5-1.2-1.7-1.2c-1.1,0-1.5,0.6-1.5,1.1c0,0.6,0.3,0.9,1.7,1.2
			c2.1,0.5,2.6,1.2,2.6,2.3c0,1.3-1,2.1-2.7,2.1c-1.7,0-2.7-0.9-2.9-2.2H655.2z'
				/>
				<path
					className='st0'
					d='M663.6,396.9v3.8h-1v-8.3c0-0.6,0-1.2,0-1.8h0.9c0,0.3,0,0.7,0,1.2c0.4-0.8,1.2-1.4,2.5-1.4
			c1.7,0,2.9,1.4,2.9,3.6c0,2.6-1.4,4-3.1,4C664.6,398,664,397.5,663.6,396.9z M668,394.1c0-1.6-0.8-2.8-2.1-2.8
			c-1.6,0-2.3,1-2.3,2.9c0,1.8,0.5,2.9,2.2,2.9C667.2,397.2,668,396,668,394.1z'
				/>
				<path
					className='st0'
					d='M678.1,394.2c0,2-1.1,3.8-3.3,3.8c-2,0-3.2-1.6-3.2-3.8c0-2.1,1.2-3.8,3.3-3.8
			C676.8,390.5,678.1,392,678.1,394.2z M672.5,394.3c0,1.7,0.9,2.9,2.3,2.9c1.4,0,2.3-1.2,2.3-2.9c0-1.7-0.8-2.9-2.3-2.9
			C673.3,391.3,672.5,392.6,672.5,394.3z'
				/>
				<path
					className='st0'
					d='M681,392.5c0-0.6,0-1.2,0-1.8h0.9c0,0.2,0,1.1,0,1.3c0.3-0.7,0.9-1.5,2.3-1.5c1.3,0,2.4,0.8,2.4,2.7v4.7h-1
			v-4.5c0-1.2-0.5-2-1.7-2c-1.6,0-2.1,1.2-2.1,2.8v3.7h-1V392.5z'
				/>
				<path
					className='st0'
					d='M690.5,395.8c0.2,0.9,0.9,1.4,1.9,1.4c1.2,0,1.7-0.5,1.7-1.3c0-0.8-0.3-1.1-1.8-1.5c-2-0.5-2.4-1.1-2.4-2
			c0-1,0.7-2,2.5-2c1.7,0,2.5,1,2.6,2H694c-0.1-0.5-0.5-1.2-1.7-1.2c-1.1,0-1.5,0.6-1.5,1.1c0,0.6,0.3,0.9,1.7,1.2
			c2.1,0.5,2.6,1.2,2.6,2.3c0,1.3-1,2.1-2.7,2.1c-1.7,0-2.7-0.9-2.9-2.2H690.5z'
				/>
				<path
					className='st0'
					d='M698.1,387.3h1v1.5h-1V387.3z M698.1,390.7h1v7.2h-1V390.7z'
				/>
				<path
					className='st0'
					d='M702.6,387.3h1v4.5c0.3-0.7,1-1.3,2.4-1.3c1.7,0,3,1.4,3,3.6c0,2.2-1.1,3.9-3.1,3.9c-1.2,0-1.8-0.5-2.2-1.2
			c0,0.4,0,0.9,0,1.1h-0.9c0-0.8,0-1.6,0-2.4V387.3z M707.9,394.1c0-1.7-0.8-2.8-2.1-2.8c-1.7,0-2.3,1.1-2.3,2.9
			c0,1.6,0.5,2.9,2.2,2.9C707.1,397.2,707.9,396,707.9,394.1z'
				/>
				<path className='st0' d='M711.9,397.9v-10.5h1v10.5H711.9z' />
				<path
					className='st0'
					d='M716.6,390.7c1.3,3.8,2,5.6,2.2,6.4h0c0.3-0.9,0.8-2.5,2.1-6.4h1l-2.7,7.6c-0.7,2.1-1.3,2.6-2.7,2.6
			c-0.2,0-0.5,0-0.7,0v-0.9c0.2,0,0.4,0,0.6,0c1,0,1.3-0.5,1.8-1.8l-2.7-7.5H716.6z'
				/>
				<path className='st0' d='M723.3,397.9v-2h1v2H723.3z' />
			</g>
			<g classNames='letters'>
				<path
					className='st1 letter'
					d='M482.8,346.3v-61.5h-21v45.8c0,18.7-5.1,25.5-17.7,25.5c-11.7,0-14.9-8.8-14.9-19v-52.4h-18.1h0l-9.6,0
			c4,0,6.7,3,6.7,5.8v50.8c0,22.5,12.2,32.1,28.8,32.1c11.9,0,19.8-4,24.7-13c0.1,3.8,0.3,8.7,0.6,11H483
			C482.9,364.6,482.8,354.7,482.8,346.3z'
				/>
				<path
					className='st1 letter'
					d='M570.2,332.5c0,13.1,6.6,24.4,19.5,24.4c11.2,0,14.6-5,17.1-10.6h21c-3.2,10.8-12.6,27.3-38.7,27.3
			c-28.1,0-40.1-21.6-40.1-44.2c0-26.7,13.6-46.5,41-46.5c29.2,0,39,21.8,39,42.4c0,2.8,0,4.8-0.3,7.3H570.2z M607.9,319.2
			c-0.2-11.5-5.3-21-18-21c-12.8,0-18.1,8.8-19.2,21H607.9z'
				/>
				<path
					className='st1 letter'
					d='M721.7,303.5v-16.6H714h-1.6h-57.4v0l-6.7,0c4,0,6.7,3,6.7,5.8c0,0,0,0,0,0v10.9h43h0l-45.2,53.8v16h7.6h2.6
			h58.7v-16.8h-44.4l1.6-1.8v0L721.7,303.5z'
				/>
				<path
					className='st1 letter'
					d='M355,284.8c-14.4,0-22.1,6.5-27,14c-3.1-7.6-10.5-14-22.9-14c-13,0-20.2,6.5-24.5,13.4l0.1-11.3h-18.1l0,0
			l-9.6,0c4,0,6.7,3,6.7,5.8c0,0,0,0,0,0c0.1,5.5,0.1,11,0.1,16.5v64.3h20.7v-48.3c0-15.4,5.3-23.2,16.5-23.2
			c11.4,0,13.9,8.2,13.9,17.9v53.6h20.6v-49.6c0-14,4.7-21.9,16.2-21.9c11.1,0,14.2,8.6,14.2,17.2v54.3h20.5v-56.9
			C382.4,293.8,369.8,284.8,355,284.8z'
				/>
				<path
					className='st1 letter'
					d='M508.9,259.2L508.9,259.2h-6.7c4,0,6.7,3,6.7,5.8v108.5h21V259.2H508.9z'
				/>
			</g>
			<g classNames='mules'>
				<g>
					<g>
						<path
							className='st1 mule'
							d='M169.8,373.5c-10.6,0-16.4-8.5-16.4-14.3c0-1.6-0.4-4.4-0.7-6c-1.6-4.9-4.6-13.2-6.2-16
					c-3.2-5.7-7.6-21.4-4.3-31.1c1-3.1,4.4-4.7,7.4-3.7c3.1,1,4.7,4.4,3.7,7.4c-1.9,5.5,1.1,17.6,3.4,21.5
					c2.7,4.8,6.8,17.1,7.2,18.5c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.5,1,5.1,1.1,8.5c0.2,0.6,1.4,2.8,4.7,2.8c3.2,0,4.5-2.2,4.7-2.8
					c0-3.4,0.9-8,1.1-8.5c0-0.2,0.1-0.4,0.2-0.6c0.5-1.4,4.5-13.7,7.2-18.5c2.2-3.9,5.3-16,3.4-21.5c-1-3.1,0.6-6.4,3.7-7.4
					c3.1-1,6.4,0.6,7.4,3.7c3.3,9.7-1,25.4-4.3,31.1c-1.6,2.8-4.6,11.1-6.2,16c-0.3,1.6-0.8,4.4-0.8,6
					C186.2,365,180.4,373.5,169.8,373.5z'
						/>
						<path className='st1' d='M174.5,358.9L174.5,358.9L174.5,358.9z' />
						<path className='st1' d='M165.1,358.9' />
					</g>
				</g>
				<g>
					<g>
						<path
							className='st1 mule'
							d='M145.4,294.7c-1.6,0-3.1-0.6-4.2-1.9c-1.3-1.4-1.7-3.3-1.4-5c-0.5-0.6-1.2-1.2-1.7-1.7c-2.5-2.5-5-5-5-8.6
					c0-2,0.5-3.8,0.8-5.2c0.4-1.4,0.6-2.3,0.5-3.2c-0.3-3-3.4-7.5-6.3-11.9c-4.9-7.4-10.5-15.7-10.5-25.3c0-15.1,9.5-33.7,9.9-34.5
					c1.1-2.1,3.3-3.3,5.6-3.1c2.3,0.2,4.3,1.8,5,4c0,0.1,4.6,14.9,11.3,27.8c7.2,13.9,10.2,21.7,10.3,22c1.1,3-0.3,6.3-3.3,7.4
					c-2.9,1.2-6.3-0.3-7.4-3.3c0-0.1-3-7.6-9.8-20.9c-2.7-5.2-5-10.7-6.9-15.4c-1.7,5.1-3.2,10.9-3.2,16c0,6.1,4.4,12.6,8.6,18.9
					c3.9,5.8,7.5,11.2,8.2,17c0.4,3.1-0.3,5.6-0.8,7.4c-0.1,0.4-0.2,0.8-0.3,1.1c0.4,0.5,1,1.1,1.5,1.5c2.7,2.6,5.7,5.6,5.6,9.7
					c0,2.1-0.9,4-2.5,5.5C148.2,294.2,146.8,294.7,145.4,294.7z'
						/>
						<path
							className='st1'
							d='M141.6,284.7C141.6,284.7,141.6,284.7,141.6,284.7C141.6,284.7,141.6,284.7,141.6,284.7z'
						/>
					</g>
				</g>
				<g>
					<g>
						<path
							className='st1 mule'
							d='M194.2,294.7c-1.5,0-3.1-0.6-4.2-1.8c-5.3-5.7,0.8-12,3.4-14.7c0.5-0.5,1.1-1.2,1.6-1.7
					c-0.1-0.8-0.3-1.8-0.5-2.7c-0.3-1.6-0.6-3.2-0.7-4.9c-0.3-6.7,3.9-12.8,8.4-19.2c4.1-5.9,8.4-12,8.4-17.6c0-5.1-1.4-10.9-3.2-16
					c-1.9,4.8-4.3,10.2-6.9,15.4c-6.8,13.3-9.8,20.8-9.8,20.9c-1.2,2.9-4.5,4.4-7.4,3.3c-3-1.1-4.4-4.5-3.3-7.4
					c0.1-0.3,3.2-8.1,10.3-22c6.6-12.8,11.2-27.6,11.3-27.8c0.7-2.2,2.7-3.8,5-4c2.3-0.2,4.5,1,5.6,3.1c0.4,0.8,9.9,19.4,9.9,34.5
					c0,9.2-5.6,17.2-10.4,24.2c-3.1,4.4-6.5,9.3-6.4,12.1c0,0.9,0.3,2,0.5,3.2c0.4,1.8,0.8,3.9,0.8,6.1c0,3.5-2.5,6.1-4.9,8.6
					c-0.5,0.5-1.2,1.3-1.8,1.9c0.3,1.8-0.3,3.8-1.8,5.1C197,294.2,195.6,294.7,194.2,294.7z'
						/>
						<path
							className='st1'
							d='M198.4,285C198.4,285,198.4,285,198.4,285C198.4,285,198.4,285,198.4,285z'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
export default SvgComponent;
